import { Children, cloneElement } from 'react';

import { omit } from 'pkg/objects';

let parsedRoutes = [];

export const getParsedRoutes = () => parsedRoutes;

/**
 *	Parses as route tree into a flat set of route definitions.
 */
export default function parser(children) {
	const parse = (children, parent = null) => {
		children = Array.from(Children.toArray(children));

		return []
			.concat(
				...children.map((child) => {
					let { path, redirectTo, component, before, after } = child.props;
					let parentPattern,
						guid = null;

					if (!path) {
						path = '/';
					}

					// Prepend parent path if parent is present
					if (parent) path = [parent.props.path, path].join('/');

					// Clean the path from leading and trailing slashes
					path = path.replace(/\/+/g, '/').replace(/\/+$/, '');

					if (redirectTo) {
						redirectTo = redirectTo.replace(/\/+/g, '/').replace(/\/+$/, '');
					}

					if (!before) {
						before = [];
					}

					if (!after) {
						after = [];
					}

					// Inherit parent component and middlewares
					if (parent && parent.props) {
						if (parent.props.component && !component) {
							component = parent.props.component;
						}

						if (parent.props.before && parent.props.before.length > 0) {
							before = [...parent.props.before, ...before];
						}

						if (parent.props.after && parent.props.after.length > 0) {
							after = [...parent.props.after, ...after];
						}
					}

					before = before.filter((callback) => callback instanceof Function);
					after = after.filter((callback) => callback instanceof Function);

					const patternProps = {};

					if (parent && parent.props) {
						Object.entries(omit(parent.props, 'children')).forEach(
							([key, value]) => {
								if (!child.props.hasOwnProperty(key)) {
									patternProps[key] = value;
								}
							}
						);

						if (path !== parent.props.path) {
							parentPattern = parent.props.path;
						}
					}

					guid = `@${path || 'root'}`.replace(':', '').replace(/\/+/g, '.');

					let isRoot =
						!parent && child.props.children && child.props.children.length > 0;

					// @NOTE Make sure IndexRoute is root
					if (!isRoot && child.props.path === '/') {
						isRoot = true;
					}

					let componentName = 'unnamed';

					if (component?.name) {
						componentName = component.name;
					} else if (component?.displayName) {
						componentName = component.displayName;
					}

					let route = {
						...child.props,
						...patternProps,
						parentPattern,
						guid,
						path,
						redirectTo,
						component,
						componentName,
						before,
						after,
						isRoot,
					};

					// Ignore empty components but keep props, except if its a redirect route
					if (!component && !redirectTo) route = null;

					if (child.props.children) {
						// @NOTE Pass in inherited props from parent
						let nextChild = cloneElement(child, {
							path,
							component,
							before,
							after,
						});

						return [route, ...parse(child.props.children, nextChild)];
					}

					return route;
				})
			)
			.filter((n) => n);
	};

	const uniqueGuids = [];

	const routes = parse(children)
		.map((route) => omit(route, 'children'))
		.filter((route) => {
			if (!uniqueGuids.includes(route.guid)) {
				uniqueGuids.push(route.guid);

				// Create a named key based on path, if not already set
				if (!route.name) {
					let namedKeyParts = route.path
						.split('/')
						.map((n) => n.replace(/:\w+/, ''))
						.filter((n) => n);

					namedKeyParts = [...new Set(namedKeyParts)];

					route.name = namedKeyParts.join('.');
				}

				return route;
			}

			return null;
		});

	const names = routes.map((r) => r.name);

	const dupedNames = ((list) => {
		let seen = new Set();
		let store = [];

		list.filter(
			(item) =>
				seen.size === seen.add(item).size &&
				!store.includes(item) &&
				store.push(item)
		);

		return store;
	})(names);

	if (dupedNames.length > 0) {
		throw new Error(
			`Route names should be unique, clashing names are: ${dupedNames.join(
				', '
			)}`
		);
	}

	parsedRoutes = routes;

	return routes;
}
