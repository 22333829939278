interface AppConfig {
	googleAnalyticsKey?: string;
	sentryDsn?: string;
	debug?: string;
	assetUrl?: string;
	hubspotPortalId?: string;
	hubspotFormGuid?: string;
	apiUrl?: string;
	beta?: boolean;
	dev?: boolean;
	devServer?: boolean;
	version?: string;
	isApp?: boolean;
	isIOS?: boolean;
	isAndroid?: boolean;
	prefabKey?: string;
	config?: {
		socketUrl: string;
		adsEnabled: boolean;
		formsServiceUrl: string;
	};
}

let appConfig: AppConfig;
let configIsSet = false;
export function setConfig(config: AppConfig): void {
	appConfig = config;
	configIsSet = true;
}

export function getConfig(): AppConfig {
	if (!configIsSet) {
		throw new Error('config not set yet');
	}
	const defaultConfig: AppConfig = {
		googleAnalyticsKey: '',
		sentryDsn: '',
		debug: '',
		assetUrl: '',
		hubspotPortalId: '',
		hubspotFormGuid: '',
		apiUrl: '',
		version: '',
		config: {
			socketUrl: '',
			adsEnabled: false,
			formsServiceUrl: '',
		},
	};
	return appConfig || defaultConfig;
}

export function majorVersion(): number {
	let ver = Number.parseInt(getConfig().version.split('.')[0], 10);
	if (Number.isNaN(ver)) {
		ver = 0;
	}
	return ver;
}

export function minorVersion(): number {
	let ver = Number.parseInt(getConfig().version.split('.')[1], 10);
	if (Number.isNaN(ver)) {
		ver = 0;
	}
	return ver;
}

let isTesting = false;
export function setIsTesting(): void {
	isTesting = true;
}
export function isTest(): boolean {
	return isTesting;
}
