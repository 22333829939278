import { getConfig } from 'pkg/config/app';

export const isWeb = (): boolean => !getConfig()?.isApp;

export const isApp = (): boolean => !isWeb();

export const isAndroid = (): boolean => isApp() && getConfig().isAndroid;

export const isIOS = (): boolean => isApp() && getConfig().isIOS;

export const usesHardwareVolumeControls = (): boolean => {
	const audio = new Audio();

	// Will fail on iOS devices
	audio.volume = 0.5;

	return audio.volume === 1;
};

export const isTouchDevice = (): boolean =>
	window.matchMedia('(pointer: coarse)').matches;
