import * as Sentry from '@sentry/react';

import { getConfig } from 'pkg/config/app';

import { isBeta, isDev } from 'pkg/flags';

let sentryEnabled = false;

// inits Sentry logger
function init() {
	let logLevels: string[] = [];
	if (isDev()) {
		logLevels = ['log', 'info', 'warn', 'assert'];
	} else if (isBeta()) {
		logLevels = ['warning'];
	}

	const sentryConfig: Sentry.BrowserOptions = {
		dsn: getConfig().sentryDsn,
		integrations: [
			Sentry.captureConsoleIntegration({
				levels: logLevels,
			}),
		],
		beforeSend: filterErrors,
	};

	if (getConfig().version) {
		sentryConfig.release = getConfig().version;
	}

	if (getConfig().sentryDsn) {
		sentryEnabled = true;
		Sentry.init(sentryConfig);

		Sentry.addBreadcrumb({
			category: 'sentry',
			message: 'Sentry initialized',
			level: 'info',
			data: {
				version: getConfig().version,
			},
		});
	}
}

function filterErrors(event: Sentry.ErrorEvent) {
	if (event.exception && event.exception.values) {
		const shouldIgnore = event.exception.values.some((ex) => {
			const message = ex.value || '';
			const type = ex.type || '';

			return (
				type.includes('AbortError') ||
				message.includes('Failed to fetch') ||
				message.includes('NetworkError when attempting to fetch resource') ||
				message.includes('Load failed')
			);
		});

		if (shouldIgnore) {
			return null;
		}
	}
	return event;
}

interface ErrorInfo {
	[key: string]: unknown;
}

function captureMessage(
	msg: string | Error,
	level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug',
	data?: ErrorInfo
) {
	Sentry.addBreadcrumb({
		category: 'log',
		message: typeof msg === 'string' ? msg : msg.message,
		level,
		data,
	});

	// only send errors to Sentry
	if (sentryEnabled && ['fatal', 'error', 'warning'].includes(level)) {
		Sentry.withScope((s) => {
			if (data) {
				s.setExtras(data);
			}
			if (msg instanceof Error) {
				Sentry.captureException(msg);
			} else {
				Sentry.captureMessage(msg, level);
			}
		});

		return;
	}

	if (!isDev()) {
		return;
	}

	switch (level) {
		case 'debug':
			console.debug(msg, data);
			break;
		case 'warning':
			console.warn(msg, data);
			break;
		case 'error':
			console.error(msg, data);
			break;
		default:
			console.info(msg, data);
			break;
	}
}

function setUserContext(identity: string) {
	if (!sentryEnabled) {
		return;
	}

	if (sentryEnabled) {
		Sentry.setUser({
			id: identity,
		});

		Sentry.addBreadcrumb({
			category: 'auth',
			message: `User set: ${identity}`,
			level: 'info',
		});
	}

	Sentry.setUser({
		id: identity,
	});
}

function debug(msg: string, data?: ErrorInfo) {
	captureMessage(msg, 'debug', data);
}

function message(msg: string, data?: ErrorInfo) {
	captureMessage(msg, 'info', data);
}

function warning(msg: string, data?: ErrorInfo) {
	captureMessage(msg, 'warning', data);
}

function error(err: Error, data?: ErrorInfo) {
	captureMessage(err, 'error', data);
}

/**
 * Sets the crash scope for Sentry reporting.
 * - view: The crash occurred in a view. This is a recoverable error for the user by navigating away from the current page.
 * - full: The crash occurred in the full app, hard to recover from for a user.
 * - partial: The crash occurred in a component, and doesn't crash the entire view or page.
 *
 * @param crashScope - The scope of the crash, can be 'view', 'full', or 'partial'.
 * @returns A function that sets the crash scope in the provided Sentry scope.
 */
function setCrashScope(
	crashScope: 'view' | 'full' | 'partial'
): (
	scope: Sentry.Scope,
	error: unknown,
	componentStack: string | undefined
) => void {
	return (scope: Sentry.Scope) => {
		scope.setTag('crash_scope', crashScope);
		switch (crashScope) {
			case 'view':
				scope.setLevel('error');
				break;
			case 'full':
				scope.setLevel('fatal');
				break;
			case 'partial':
				scope.setLevel('warning');
				break;
		}
	};
}

export { init, setUserContext, debug, message, warning, error, setCrashScope };
