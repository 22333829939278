import * as logger from 'pkg/logger';

export function parse<T = JSONObject>(unparsed: string): T {
	let parsed: T;

	if (!unparsed || unparsed === '{}') {
		return null;
	}

	try {
		parsed = JSON.parse(unparsed);
	} catch (e) {
		logger.error(new Error('json parsing failed', { cause: e }));

		return null;
	}

	return parsed;
}

export function stringify<T = any>(
	serializable: T,
	prettify: boolean = false
): Nullable<string> {
	let serialized: Nullable<string> = null;

	try {
		serialized = JSON.stringify(serializable, null, prettify ? '  ' : '');
	} catch (e) {
		logger.error(new Error('json stringifying failed', { cause: e }));
	}

	return serialized;
}
