import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { SafeArea } from '@capacitor-community/safe-area';

import { setConfig } from 'pkg/config/app';

import { pushState } from 'pkg/router/state';
import { isAndroid, isApp, isIOS } from 'pkg/platform';
import { setAppleMeta } from 'pkg/meta_tags';
import { isDev } from 'pkg/flags';
import * as logger from 'pkg/logger';

import 'pkg/core/sdk';
import 'pkg/dev/generateRouteDocs';

setConfig(window.TS);

window.handleOpenURL = (url: string) => {
	//incoming url includes custom-url-scheme.
	const [protocol, location] = url.split('://');

	// handleOpenURL also receives all calls to window.open, leading to external links being pushed to the state.
	if (!protocol.startsWith('threesixty')) {
		return;
	}

	pushState(location);
};

async function init() {
	if (isDev() && !isApp()) {
		import('pkg/dev/consoleActions');
	}

	if (isApp()) {
		SplashScreen.hide();
	}

	if (isIOS()) {
		Keyboard.setAccessoryBarVisible({ isVisible: true });
	}

	if (isAndroid()) {
		// config needs to be populated for SafeArea to work
		SafeArea.enable({
			config: {
				customColorsForSystemBars: true,
				statusBarColor: '#00000000',
				statusBarContent: 'dark',
				navigationBarColor: '#00000000',
				navigationBarContent: 'dark',
			},
		});
	}

	logger.init();

	setAppleMeta(location.pathname + location.search + location.hash);

	window.addEventListener('statusTap', function () {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});

	const render = await import('./render');
	render.renderApp();
}

document.addEventListener(isApp() ? 'deviceready' : 'DOMContentLoaded', init);
